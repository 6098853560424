import axios from "../utils/axios";
// import { psm as API_PSM, warning as API_WARNING } from "../utils/axios";

import apiPath from "../utils/apiPath";
const API_PSM = apiPath.psm;
const API_WARNING = apiPath.warning;

export default {
  // 获取报警数据
  getAlarmClassify(params) {
    return axios.get(API_WARNING + "/classify", { params });
  },

  // 获取隐患数据
  getTroubleStatistics(params) {
    return axios.get(API_PSM + "/statistics/situation/now", { params });
  },

  // 获取作业数据
  getWorksTypeStatistics(params) {
    return axios.get(API_PSM + "/works/type-statistics/app", { params });
  }
};
