<template>
  <div class="analysis">
    <van-nav-bar
      title="报警统计"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="analysis-container">
      <date-time v-model="dateTime" type="date" :time-close="false" />
      <div id="chart" class="chart-box"></div>
    </div>
  </div>
</template>

<script>
import analysisApi from "../../api/analysis";
import echarts from "echarts/lib/echarts";
import { mapState } from "vuex";
import { getRecentDays } from "../../utils/index";
import dayjs from "dayjs";

require("echarts/lib/chart/line"); // 按需导入折线组件
require("echarts/lib/chart/pie"); // 按需导入折线组件
require("echarts/lib/chart/effectScatter"); // 拐点出闪烁，高亮
require("echarts/lib/component/tooltip"); // 提示组件
require("echarts/lib/component/legend");
require("echarts/lib/component/grid");
require("echarts/lib/component/dataZoom");

export default {
  name: "AlarmAnalysis",
  data() {
    return {
      dateTime: getRecentDays(14)
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  watch: {
    dateTime: {
      handler: function(val) {
        if (val.start && val.end) {
          this.getAlarmClassify();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getAlarmClassify();
  },
  methods: {
    draw(options) {
      let option = { ...options };
      const element = document.getElementById("chart", null, {
        renderer: "svg"
      });
      if (element) {
        let myChart = echarts.init(element);
        myChart.clear();
        myChart.setOption(option);
      }
    },
    setParams() {
      return {
        startTime: dayjs(this.dateTime.start).format("YYYY-MM-DD HH:mm:ss"),
        endTime: `${dayjs(this.dateTime.end).format("YYYY-MM-DD")} 23:59:59`,
        orgCode: this.userInfo.orgCode
      };
    },
    getAlarmClassify() {
      let params = this.setParams();
      analysisApi.getAlarmClassify(params).then(res => {
        let options = {
          color: ["#9ED05A", "#4596F0"],
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgb(255,255,255)",
            textStyle: {
              color: "rgb(52,57,78)",
              fontSize: 12
            },
            padding: [5, 10],
            extraCssText: "box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);",
            axisPointer: {
              z: -1,
              lineStyle: {
                color: "#ddd"
              }
            }
          },
          legend: {
            icon: "roundRect",
            bottom: "10px",
            itemGap: 24,
            itemWidth: 12,
            itemHeight: 12,
            data: ["一级报警", "二级报警"]
          },
          grid: {
            left: "5%",
            right: "5%",
            bottom: "10%",
            containLabel: true
          },
          dataZoom: [
            {
              type: "inside",
              realtime: true,
              start: 0,
              end: 100,
              minValueSpan: 3
            }
          ],
          xAxis: {
            type: "category",
            boundaryGap: false,
            axisTick: {
              show: true
            },
            axisLabel: {
              rotate: 60,
              fontSize: 10,
              color: "rgb(59,70,100)"
            },
            axisLine: {
              lineStyle: {
                width: 0.5,
                color: "rgb(199,201,208)"
              }
            },
            data: res.xaxis || []
          },
          yAxis: {
            type: "value",
            axisTick: {
              show: false
            },
            axisLabel: {
              fontSize: 10
            },
            axisLine: {
              show: false
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                width: 0.5,
                color: "rgb(199,201,208)"
              }
            }
          },
          series: [
            {
              name: "一级报警",
              type: "line",
              stack: "总量",
              data: res.firstAlarmLevel || [],
              symbol: "circle",
              itemStyle: {
                color: "#9ED05A"
              },
              emphasis: {
                itemStyle: {
                  color: {
                    type: "radial",
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      { offset: 0, color: "#9ED05A" },
                      { offset: 0.5, color: "#9ED05A" },
                      { offset: 0.9, color: "#Fff" },
                      { offset: 1, color: "#fff" }
                    ],
                    global: false // 缺省为 false
                  },
                  borderColor: "#fff",
                  shadowColor: "#9ED05A",
                  shadowBlur: 8
                }
              }
            },
            {
              name: "二级报警",
              type: "line",
              stack: "总量",
              data: res.secondAlarmLevel || [],
              symbol: "circle",
              itemStyle: {
                color: "#4596F0"
              },
              emphasis: {
                itemStyle: {
                  color: {
                    type: "radial",
                    x: 0.5,
                    y: 0.5,
                    r: 0.5,
                    colorStops: [
                      { offset: 0, color: "#4596F0" },
                      { offset: 0.5, color: "#4596F0" },
                      { offset: 0.9, color: "#Fff" },
                      { offset: 1, color: "#fff" }
                    ],
                    global: false // 缺省为 false
                  },
                  borderColor: "#fff",
                  shadowColor: "#4596F0",
                  shadowBlur: 8
                }
              }
            }
          ]
        };
        this.draw(options);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.analysis {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .van-nav-bar {
    height: 15.5vw;
  }
  .analysis-container {
    flex: 1;
  }
  .chart-box {
    height: 60vh;
  }
}
</style>
